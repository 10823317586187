<template>
  <div class="product-page">
    <div class="product-image">
      <img :src="require(`@/assets/img/${product.img}`)" :alt="product.title" />
    </div>
    <div class="product-details">
      <h1 class="product-title">{{ product.title }}</h1>
      <p class="product-description">{{ product.description }}</p>
      <p class="product-price">Цена: <span>{{ product.price }} ₽</span></p>
      <div class="additional-info" v-if="product.instruction">
        <h2>Дополнительная информация</h2>
        <p v-html="product.instruction"></p>
      </div>
      <div class="button-group">
        <button @click="openEmailModal" class="buy-button">Купить</button>
        <button @click="goBack" class="back-button">Назад</button>
      </div>
    </div>

    <!-- Модальное окно для ввода email -->
    <div v-if="showEmailModal" class="modal-overlay">
      <div class="modal">
        <h2>Введите вашу электронную почту</h2>
        <input 
          v-model="userEmail" 
          type="email" 
          placeholder="user@example.com" 
          maxlength="64"
        />
        <p>На эту почту будет отправлен чек, который необходим для получения продукта.</p>
        <div class="modal-actions">
          <button @click="confirmEmail" class="confirm-button">Подтвердить</button>
          <button @click="closeEmailModal" class="cancel-button">Отмена</button>
        </div>
      </div>
    </div>

    <!-- Новое модальное окно для выбора между Lava и FreeKassa -->
    <div v-if="showPaymentMethodSelection" class="modal-overlay">
      <div class="modal">
        <h2>Выберите платежного провайдера</h2>
        <div class="payment-provider-selection">
          <div class="provider-tile" @click="selectMethod('FreeKassa')">
            <h3>FreeKassa</h3>
            <p>Оплата через платежный сервис FreeKassa.</p>
          </div>
          <div class="provider-tile" @click="selectMethod('Lava')">
            <h3>Lava</h3>
            <p>Оплата напрямую через Lava.</p>
          </div>
        </div>
        <div class="modal-actions">
          <button @click="closePaymentMethodSelection" class="cancel-button">Отмена</button>
        </div>
      </div>
    </div>

    <!-- Модальное окно для выбора конкретного метода оплаты FreeKassa -->
    <div v-if="showPaymentModal" class="modal-overlay">
      <div class="modal payment-modal">
        <h2>Выберите способ оплаты</h2>
        <div class="payment-methods">
          <div 
            v-for="method in paymentMethods" 
            :key="method.id" 
            class="payment-method-card"
            @click="selectPaymentMethod(method)"
          >
            <h3>{{ method.name }}</h3>
            <p>Валюта: {{ method.currency }}</p>
            <p>Мин. сумма: {{ method.limits.min }}</p>
            <p>Макс. сумма: {{ method.limits.max }}</p>
            <p>Комиссия: {{ method.fee.default }}%</p>
          </div>
        </div>
        <div class="modal-actions">
          <button @click="closePaymentModal" class="cancel-button">Отмена</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { shopItems } from '@/assets/db/shopItems.js';

export default {
  name: 'ProductPage',
  props: ['id'],
  data() {
    return {
      product: null,
      showEmailModal: false,
      showPaymentMethodSelection: false, // Новое состояние для выбора Lava или FreeKassa
      showPaymentModal: false,
      userEmail: '',
      paymentMethods: [], // Платежные методы, загружаются после выбора FreeKassa
      selectedMethod: null, // Для отслеживания выбранного способа оплаты
    };
  },
  created() {
    this.loadProduct();
  },
  methods: {
    loadProduct() {
      for (const game of shopItems) {
        const foundProduct = game.products.find(product => product.id === this.id);
        if (foundProduct) {
          this.product = foundProduct;
          this.product.instruction = this.product.instruction || game.instruction;
          break;
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    openEmailModal() {
      this.showEmailModal = true;
    },
    closeEmailModal() {
      this.showEmailModal = false;
      this.userEmail = ''; // Очистка поля ввода
    },
    openPaymentModal() {
      this.showPaymentModal = true;
    },
    closePaymentModal() {
      this.showPaymentModal = false;
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    async confirmEmail() {
      if (!this.userEmail || !this.validateEmail(this.userEmail)) {
        alert('Пожалуйста, введите корректный email');
        return;
      }

      // Переход к выбору Lava или FreeKassa
      this.showEmailModal = false;
      this.showPaymentMethodSelection = true;
    },
    async fetchPaymentMethods() {
      try {
        const result = await axios.post('http://zanders.store/api/v1/payments/payment-get-currencies');
        this.paymentMethods = result.data; // Предполагается, что данные возвращаются в формате массива
      } catch (error) {
        console.error('Ошибка при получении платежных методов:', error);
      }
    },
    selectMethod(method) {
      this.selectedMethod = method;

      if (method === 'Lava') {
        // Если выбрана Lava, сразу выполняем редирект на страницу оплаты
        this.redirectToLavaPayment();
      } else if (method === 'FreeKassa') {
        // Если выбрана FreeKassa, загружаем методы оплаты и переходим к следующему окну
        this.fetchPaymentMethods();
        this.showPaymentMethodSelection = false;
        this.showPaymentModal = true;
      }
    },
    selectPaymentMethod(method) {
      // Логика для выбора метода оплаты и отправки платежного запроса
      this.closePaymentModal();
      this.createOrder('FreeKassa', method);
    },
    async createOrder(kassaMethod, selectedMethod) {
      try {
        const response = await axios.post(`https://zanders.store/api/v1/payments/create-payment`, {
          kassaType: kassaMethod,
          amount: this.product.price,
          item: {
            productId: this.product.id,
            Name: this.product.title,
            Price: this.product.price,
            Quantity: 1,
            Amount: this.product.price * 1 * 100,
            Tax: 'none',
          },
          paymentTypeId: Number(selectedMethod.id),
          email: this.userEmail,
        });

        console.log(response.data);

        if (response.data) {
          window.location.href = response.data;
        } else {
          alert('Произошла ошибка при создании платежного намерения. Сумма меньше минимальной или больше максимальной.');
        }
      } catch (error) {
        console.error('Ошибка при попытке создания платежного намерения:', error);
        alert('Ошибка при попытке создания платежного намерения.');
      }
    },
    redirectToLavaPayment() {
      this.createOrder('Lava', {id: '1'});
    },
    getPaymentMethodImage(method) {
      // Функция для получения URL изображения метода оплаты
      return require(`@/assets/payments/${method.name.toLowerCase().replace(/ /g, '-')}.png`);
    }
  }
};
</script>

<style scoped>
.product-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #1f1f2f;
  color: #ffffff;
}

.product-image {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.product-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.product-image img:hover {
  transform: scale(1.05);
}

.product-details {
  max-width: 800px;
  text-align: center;
  padding: 20px;
  background-color: #2e2e44;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-title {
  font-size: 2rem;
  color: #00ffb9;
  margin-bottom: 20px;
}

.product-description {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #cccccc;
}

.product-price {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #00ffb9;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.product-price::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 3px;
  background-color: #00ffb9;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.product-price span {
  font-size: 2.5rem;
  color: #00ffb9;
  font-weight: bold;
  padding-left: 10px;
}

.product-price:hover::before {
  transform: scaleX(1);
}

.additional-info {
  text-align: left;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #3b3b57;
  border-radius: 10px;
  color: #e0e0e0;
}

.additional-info h2 {
  font-size: 1.5rem;
  color: #00ffb9;
  margin-bottom: 10px;
}

.additional-info p {
  font-size: 1rem;
  line-height: 1.5;
  white-space: pre-line;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.buy-button,
.back-button {
  background-color: #00ffb9;
  color: #1f1f2f;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buy-button:hover,
.back-button:hover {
  background-color: #00c895;
}

/* Модальное окно */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #2e2e44;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.modal input {
  width: 90%;
  padding: 10px;
  margin: 20px 0;
  border-radius: 5px;
  border: 1px solid #00ffb9;
  background-color: #3b3b57;
  color: #00ffb9;
}

.modal-actions {
  display: flex;
  justify-content: space-around;
}

.confirm-button,
.cancel-button {
  background-color: #00ffb9;
  color: #1f1f2f;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-button:hover,
.cancel-button:hover {
  background-color: #00c895;
}

/* Стили для модальных окон */
.modal.payment-modal {
  background-color: #2e2e44;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

/* Новое модальное окно выбора платежного провайдера */
.modal.payment-provider-modal {
  max-width: 500px;
}

.payment-provider-selection {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.provider-tile {
  background-color: #3b3b57;
  border: 1px solid #00ffb9;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.provider-tile:hover {
  transform: scale(1.05);
  background-color: #444466;
}

.payment-methods {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.payment-method-card {
  background-color: #3b3b57;
  border-radius: 10px;
  padding: 15px;
  width: 200px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.payment-method-card:hover {
  transform: scale(1.05);
  background-color: #444466;
}

.payment-method-image {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.close-button {
  background-color: #00ffb9;
  color: #1f1f2f;
  border: none;
  border-radius: 5px;
  padding: 24px 48px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 40px;
}

.close-button:hover {
  background-color: #00c895;
}

/* Медиа запросы */
@media (min-width: 768px) {
  .product-page {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 40px;
  }

  .product-image {
    margin-right: 40px;
  }

  .product-details {
    text-align: left;
  }
}

/* Мобильная адаптация */
@media (max-width: 768px) {
  .payment-method-card {
    width: 100%;
  }
}
</style>