<template>
  <div class="home-container">
    <div class="about-us">
      <h2 class="about-title">Немножечко о нас</h2>
      <p>
        Добро пожаловать в Zanders, ваш надежный интернет-магазин цифровых товаров! Мы рады видеть вас среди наших клиентов. За три года работы мы заслужили доверие более 6000 клиентов, получив положительные отзывы. Это доказывает наш высокий уровень сервиса и приверженность качеству.
        <br><br>
        Мы специализируемся на продаже доната для игр и гарантируем самые низкие цены на рынке! Наша цель — сделать игровые достижения доступными для всех, предлагая выгодные условия каждому покупателю. Безопасность и легальность всех предложений подтверждены официальными способами работы, соответствующими всем стандартам безопасности. Вы можете быть спокойны за свои покупки.
        <br><br>
        Почему выбирают нас?
        <br><br>
        Наши клиенты ценят нас за:

        <ul>
          <li>Качество продукции</li>
          <li>Низкие цены</li>
          <li>Комфортные условия</li>
          <li>Быструю доставку</li>
          <li>Дружественную поддержку</li>
        </ul>

        <br><br>
        Мы сопровождаем вас на каждом этапе — от момента заказа до его получения, предоставляя всю необходимую информацию и помощь. Нам важно, чтобы вы были довольны каждой покупкой и чувствовали себя защищёнными. Мы стремимся сделать ваш опыт с нами максимально комфортным и приятным.
        <br><br>
        Спасибо, что выбираете Zanders. Мы готовы помочь вам сделать ваш игровой опыт ещё более захватывающим и увлекательным. Ваше доверие вдохновляет нас на новые достижения, и мы постоянно работаем над улучшением наших услуг, чтобы предложить вам только лучшее.
      </p>
    </div>

    <div class="popular-products">
      <h2>Популярные товары</h2>
      <div class="products-list">
        <ProductCard 
          v-for="product in popularProducts" 
          :key="product.id" 
          :title="product.title" 
          :price="product.price + ' руб.'" 
          :img="require(`@/assets/img/${product.img}`)" 
          :id="product.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import { shopItems } from '@/assets/db/shopItems.js';

export default {
  name: "HomePage",
  components: {
    ProductCard
  },
  data() {
    return {
      popularProductIds: ['VP3650', 'VP5350', 'VP8700'], // Уникальные ID популярных товаров
      popularProducts: []
    };
  },
  created() {
    this.loadPopularProducts();
  },
  methods: {
    loadPopularProducts() {
      // Проходим по всем играм и их товарам
      shopItems.forEach(game => {
        game.products.forEach(product => {
          // Если ID товара совпадает с одним из популярных ID, добавляем его в popularProducts
          if (this.popularProductIds.includes(product.id)) {
            this.popularProducts.push(product);
          }
        });
      });
    }
  }
};
</script>

<style>
.home-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.about-us {
  text-align: left;
  flex: 1;
  padding-right: 20px;
  margin-bottom: 20px;
}

.about-title {
  color: #00ffb9;
}

.popular-products {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.products-list {
  max-height: 550px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.popular-products h2 {
  margin-bottom: 20px;
}

.popular-products > * {
  width: 100%;
}

/* Адаптация для мобильных устройств */
@media (min-width: 768px) {
  .home-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .about-us {
    flex: 0 1 70%;
    margin-bottom: 0;
  }

  .popular-products {
    flex: 0 1 30%;
  }
}
</style>
