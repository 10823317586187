<template>
  <header
    :class="{ 'is-visible': isVisible, 'is-collapsed': isCollapsed && isMobile }"
    ref="navMenu"
    @mousedown="startDrag"
  >
    <button v-if="isMobile" class="toggle-button" @click="toggleMenu">
      ☰
    </button>
    <nav v-if="!isCollapsed || !isMobile">
      <ul>
        <li><router-link to="/">Главная</router-link></li>
        <li><router-link to="/catalog">Каталог</router-link></li>
        <li><router-link to="/about">О нас</router-link></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "NavigationMenu",
  data() {
    return {
      isVisible: true, // Видимость меню
      isCollapsed: true, // Флаг сворачивания меню (только для мобильных устройств)
      isMobile: false, // Флаг для определения мобильного устройства
      lastScrollPosition: 0, // Последняя позиция прокрутки
      offsetX: 0, // Смещение по X при перемещении
      offsetY: 0, // Смещение по Y при перемещении
      isDragging: false, // Флаг, указывающий на состояние перетаскивания
      initialPosition: {
        mobile: {
          top: "40px",
          left: "calc(100% - 60px)", // Смещаем в правый верхний угол (только для мобильных)
        },
        desktop: {
          top: "40px",
          left: "calc(100% - 400px)",
        },
      },
    };
  },
  mounted() {
    this.checkIsMobile();
    window.addEventListener("resize", this.checkIsMobile);
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("mousemove", this.drag);
    window.addEventListener("mouseup", this.stopDrag);

    this.$refs.navMenu.style.top = this.initialPosition[
      this.isMobile ? "mobile" : "desktop"
    ].top;
    this.$refs.navMenu.style.left = this.initialPosition[
      this.isMobile ? "mobile" : "desktop"
    ].left;
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkIsMobile);
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("mousemove", this.drag);
    window.removeEventListener("mouseup", this.stopDrag);
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 767;
      if (!this.isMobile) {
        this.isCollapsed = false; // Разворачиваем меню на ПК
      }
    },
    toggleMenu() {
      this.isCollapsed = !this.isCollapsed;
    },
    handleScroll() {
      const currentScrollPosition = window.pageYOffset;

      if (currentScrollPosition > this.lastScrollPosition) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }

      this.lastScrollPosition = currentScrollPosition;
    },
    startDrag(event) {
      this.isDragging = true;
      this.offsetX =
        event.clientX - this.$refs.navMenu.getBoundingClientRect().left;
      this.offsetY =
        event.clientY - this.$refs.navMenu.getBoundingClientRect().top;
      document.body.style.userSelect = "none"; // Отключаем выделение текста при перетаскивании
    },
    drag(event) {
      if (this.isDragging) {
        this.$refs.navMenu.style.left = `${event.clientX - this.offsetX}px`;
        this.$refs.navMenu.style.top = `${event.clientY - this.offsetY}px`;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.body.style.userSelect = "auto"; // Восстанавливаем возможность выделения текста
    },
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 10px;
  left: 10px;
  width: auto;
  background-color: #1f1f2f;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer; /* Изменяем курсор на значок перемещения */
}

header.is-collapsed {
  width: 50px;
  height: 50px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
}

nav ul {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
}

nav ul li {
  margin-right: 20px;
  white-space: nowrap;
}

nav ul li a {
  color: #ffffff;
  text-decoration: none;
  padding: 10px 15px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

nav ul li a:hover {
  color: #00ffb9;
  background-color: #282846;
  text-decoration: none;
  border-radius: 5px;
}

/* Медиазапросы для мобильных устройств */
@media (max-width: 767px) {
  header {
    top: 10px;
    left: 10px;
    right: 10px;
    width: 35%;
    padding: 10px; /* Уменьшаем отступы, чтобы меню не было слишком широким */
  }

  nav ul {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%; /* Устанавливаем ширину меню по содержимому */
  }

  nav ul li {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: left; /* Выравниваем текст по левому краю */
    width: 100%; /* Устанавливаем ширину элемента списка по содержимому */
  }

  nav ul li a {
    width: 100%; /* Устанавливаем ширину ссылки по содержимому */
    display: block;
    padding: 10px;
  }
}

body {
  padding-top: 80px; /* Дополнительный отступ для предотвращения наложения на контент */
}
</style>