// assets/shopItems.js
export const shopItems = [
    // {
    //     game: 'Fortnite',
    //     instruction: `
    //     После покупки необходимо связаться с нами используя Telegram: @Zanders_Sup, отправить чек об облате заказа. После проверки на ваш аккаунт зайдут и приобретут нужный предмет. Пожалуйста, убедитесь, что ваша учётная запись Epic Games связана с учётной записью Xbox. После оплаты потребуется предоставить данные от аккаунта Xbox.

    //     ⚙️ Инструкции:
        
    //     💚 Как привязать аккаунт Xbox к Epic Games?
    //     ⚠️ Создайте учётную запись Xbox → Зайдите на сайт Epic Games → Перейдите во вкладку Соединения → Выберите Xbox → Нажмите Подключить. ВАЖНО! Возраст на вашем аккаунте должен быть 18+. У вас на аккаунте не должно быть родительского контроля!

    //     💚 При привязке Xbox к Epic Games происходит ошибка. Что делать?
    //     ⚠️ Попробуйте зайти на сайт Xbox и войти в свой аккаунт, после этого попробуйте снова связать ваши учётные записи.
    //   `,

    //     products: [{
    //         id: 'VB1000',
    //         title: "1000 В-баксов",
    //         price: 631,
    //         description: "1000 В-баксов — игровая валюта, которую можно потратить в Fortnite. Приобретайте предметы для «Королевской битвы» Fortnite, LEGO® Fortnite, Fortnite Festival и Rocket Racing, а также доп. материалы, например сезонный боевой пропуск!",
    //         img: "1000vbucks.png"
    //     },
    //     {
    //         id: 'VB2800',
    //         title: "2800 В-баксов",
    //         price: 1542,
    //         description: "2800 В-баксов — игровая валюта, которую можно потратить в Fortnite. Приобретайте предметы для «Королевской битвы» Fortnite, LEGO® Fortnite, Fortnite Festival и Rocket Racing, а также доп. материалы, например сезонный боевой пропуск!",
    //         img: "2800vbucks.png"
    //     },
    //     {
    //         id: 'VB5000',
    //         title: "5000 В-баксов",
    //         price: 2397,
    //         description: "5000 В-баксов — игровая валюта, которую можно потратить в Fortnite. Приобретайте предметы для «Королевской битвы» Fortnite, LEGO® Fortnite, Fortnite Festival и Rocket Racing, а также доп. материалы, например сезонный боевой пропуск!",
    //         img: "5000vbucks.png"
    //     },
    //     {
    //         id: 'VB13500',
    //         title: "13500 В-баксов",
    //         price: 5521,
    //         description: "13500 В-баксов — игровая валюта, которую можно потратить в Fortnite. Приобретайте предметы для «Королевской битвы» Fortnite, LEGO® Fortnite, Fortnite Festival и Rocket Racing, а также доп. материалы, например сезонный боевой пропуск!",
    //         img: "13500vbucks.png"
    //     },
    // ]},

    {
        game: 'Valorant',
        instruction: `После покупки необходимо связаться с нами используя Telegram: @Zanders_Sup, отправить чек об облате заказа. После проверки вы получаете код, который можете активировать только в регионе EU/RU.

            ⚙️ Инструкция по активации кода
            1. Войдите в VALORANT
            2. Щелкните по значку VALORANT справа от вкладки "Магазин"
            3. Выберите "Карты предоплаты и коды"
            4. Введите полученный код
            5. Нажмите "Отправить"
            6. Ваш код активирован
        `,

        products: [{
            id: 'VP240',
            title: "240 VP",
            price: 279,
            description: "Очки Valorant (VP) — это форма премиальной валюты в VALORANT, которую можно получить только за реальные деньги. Они используются для покупки косметических предметов, очков Ридианита и для улучшения до премиум-трека боевых пропусков акта.",
            img: "ValorantPoints.png"
        },
        {
            id: 'VP475',
            title: "475 VP",
            price: 459,
            description: "Очки Valorant (VP) — это форма премиальной валюты в VALORANT, которую можно получить только за реальные деньги. Они используются для покупки косметических предметов, очков Ридианита и для улучшения до премиум-трека боевых пропусков акта.",
            img: "ValorantPoints.png"
        },
        {
            id: 'VP1000',
            title: "1000 VP",
            price: 899,
            description: "Очки Valorant (VP) — это форма премиальной валюты в VALORANT, которую можно получить только за реальные деньги. Они используются для покупки косметических предметов, очков Ридианита и для улучшения до премиум-трека боевых пропусков акта.",
            img: "ValorantPoints.png"
        },
        {
            id: 'VP1520',
            title: "1520 VP",
            price: 1329,
            description: "Очки Valorant (VP) — это форма премиальной валюты в VALORANT, которую можно получить только за реальные деньги. Они используются для покупки косметических предметов, очков Ридианита и для улучшения до премиум-трека боевых пропусков акта.",
            img: "ValorantPoints.png"
        },
        {
            id: 'VP2000',
            title: "2000 VP",
            price: 1849,
            description: "Очки Valorant (VP) — это форма премиальной валюты в VALORANT, которую можно получить только за реальные деньги. Они используются для покупки косметических предметов, очков Ридианита и для улучшения до премиум-трека боевых пропусков акта.",
            img: "ValorantPoints.png"
        },
        {
            id: 'VP2575',
            title: "2575 VP",
            price: 2239,
            description: "Очки Valorant (VP) — это форма премиальной валюты в VALORANT, которую можно получить только за реальные деньги. Они используются для покупки косметических предметов, очков Ридианита и для улучшения до премиум-трека боевых пропусков акта.",
            img: "ValorantPoints.png"
        },
        {
            id: 'VP3575',
            title: "3575 VP",
            price: 3199,
            description: "Очки Valorant (VP) — это форма премиальной валюты в VALORANT, которую можно получить только за реальные деньги. Они используются для покупки косметических предметов, очков Ридианита и для улучшения до премиум-трека боевых пропусков акта.",
            img: "ValorantPoints.png"
        },
        {
            id: 'VP5350',
            title: "5350 VP",
            price: 4699,
            description: "Очки Valorant (VP) — это форма премиальной валюты в VALORANT, которую можно получить только за реальные деньги. Они используются для покупки косметических предметов, очков Ридианита и для улучшения до премиум-трека боевых пропусков акта.",
            img: "ValorantPoints.png"
        },
        {
            id: 'VP8700',
            title: "8700 VP",
            price: 7299,
            description: "Очки Valorant (VP) — это форма премиальной валюты в VALORANT, которую можно получить только за реальные деньги. Они используются для покупки косметических предметов, очков Ридианита и для улучшения до премиум-трека боевых пропусков акта.",
            img: "ValorantPoints.png"
        },
        {
            id: 'VP10000',
            title: "11000 VP",
            price: 9599,
            description: "Очки Valorant (VP) — это форма премиальной валюты в VALORANT, которую можно получить только за реальные деньги. Они используются для покупки косметических предметов, очков Ридианита и для улучшения до премиум-трека боевых пропусков акта.",
            img: "ValorantPoints.png"
        },
    ]},
    {
        game: 'Technical Items',

        products: [
            {
                id: 'test10',
                title: "Тестовый товар на 10 руб",
                price: 10,
                description: "Тестовый товар, не покупайте его! Он будет удален после введение в стабильность сайта.",
                img: "1000vbucks.png"
            },
        ]
    }
  ];  