<template>
  <div>
    <h1>О сервисе</h1>
    <AboutSection :sections="aboutSections" />
  </div>
</template>

<script>
import AboutSection from '@/components/AboutSection.vue';
const aboutSections = require('@/assets/db/aboutService.js');

export default {
  name: "AboutPage",
  components: {
    AboutSection,
  },

  data() {
    return {
      aboutSections: aboutSections
    }
  }
};
</script>
