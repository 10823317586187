<template>
  <div class="product-card">
    <img :src="img" :alt="title" />
    <h2>{{ title }}</h2>
    <h4>{{ description }}</h4>
    <p>Цена: {{ price }}</p>
    <button @click="goToProductPage(id)">Купить</button>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    price: String,
    img: String,
    id: String
  },

  methods: {
    goToProductPage() {
      this.$router.push({ name: 'Product', params: { id: this.id } });
    }
  }
};
</script>

<style scoped>
.product-card {
  background-color: #2d2d44;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 200px;
  margin: 10px;
}

.product-card img {
  max-width: 100%;
  border-radius: 10px;
}

.product-card h2 {
  font-size: 1.5rem;
  color: #00ffb9;
}

.product-card p {
  font-size: 1.2rem;
}

button {
  background-color: #00ffb9;
  color: #1e1e2e;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

button:hover {
  background-color: #00c895;
}
</style>
