<template>
    <div class="payments-section">
      <img v-for="(logo, index) in logos" :key="index" :src="logo" :alt="'Logo ' + index" class="payment-logo" />
      <a href="https://freekassa.com" target="_blank" rel="noopener noreferrer">
        <img src="https://cdn.freekassa.com/banners/big-dark-1.png" title="Прием платежей на сайте для физических лиц и т.д.">
      </a>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        logos: this.importAll(require.context('@/assets/ico/banks', false, /\.(png|jpe?g|svg)$/))
      };
    },
    methods: {
      importAll(r) {
        return r.keys().map(r);
      }
    }
  };
  </script>
  
  <style>
  .payments-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Отступы между логотипами */
  }
  
  .payment-logo {
    height: 50px; /* Установите нужную высоту для логотипов */
    object-fit: contain; /* Сохранение пропорций логотипов */
  }
  </style>  