<template>
  <div class="about-container">
    <div
      class="about-section"
      v-for="(section, index) in filteredSections"
      :key="index"
      :class="getSectionClass(index)"
    >
      <h2 class="about-title">{{ section.title }}</h2>
      <p class="about-description" v-html="section.description"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutSection",
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredSections() {
      return this.sections.filter(
        (section) => section.title !== null && section.description !== null
      );
    },
  },
  methods: {
    getSectionClass(index) {
      const section = this.filteredSections[index];
      const widthPercentage = this.calculateRowWidthUpTo(index);

      if (section.description.length > 6000) {
        return "extra-wide-section";
      } else if (section.description.length > 2000) {
        return widthPercentage > 66.666 ? "adjusted-wide-section" : "wide-section";
      } else {
        return widthPercentage > 66.666 ? "adjusted-narrow-section" : "about-section";
      }
    },
    calculateRowWidthUpTo(index) {
      return this.filteredSections.slice(0, index).reduce((acc, section) => {
        if (section.description.length > 6000) {
          return acc + 100;
        } else if (section.description.length > 2000) {
          return acc + 66.666;
        }
        return acc + 33.333;
      }, 0);
    }
  },
};
</script>

<style scoped>
.about-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.about-section {
  display: inline-block;
  width: calc(33.333% - 20px); /* Три панели в ряд */
  padding: 20px;
  background-color: #2d2d44;
  border-radius: 8px;
  margin-bottom: 20px;
  box-sizing: border-box;
  text-align: left;
}

.wide-section {
  width: calc(66.666% - 20px); /* Две панели в ряд */
}

.extra-wide-section {
  width: calc(100% - 20px); /* Одна панель на всю ширину */
}

.adjusted-wide-section {
  width: calc(66.666% - 10px); /* Широкая секция, скорректированная под оставшееся место */
}

.adjusted-narrow-section {
  width: calc(33.333% - 10px); /* Узкая секция, скорректированная под оставшееся место */
}

.about-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #00ffb9;
}

.about-description {
  font-size: 16px;
  line-height: 1.6;
  color: #fff;
}

/* Адаптивный дизайн */
@media (max-width: 1024px) {
  .about-section {
    width: calc(50% - 20px); /* Два блока в ряд на планшетах */
  }

  .wide-section,
  .extra-wide-section,
  .adjusted-wide-section,
  .adjusted-narrow-section {
    width: 100%; /* На маленьких экранах такие блоки занимают всю ширину */
  }
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
  }

  .about-section {
    width: 100%; /* Блоки занимают всю ширину */
  }

  .about-title {
    font-size: 20px;
  }

  .about-description {
    font-size: 14px;
  }
}
</style>