<template>
  <div id="app">
    <header>
      <img src="./assets/logo.png" alt="Zanders Logo" class="logo" />
      <h1 class="name">Zanders</h1>

      <Navigation class="nav" />
    </header>

    <main>
      <router-view />
    </main>

    <footer>
      <ContactSection />
      <PaymentsSection />
    </footer>
    
  </div>
</template>

<script>
import ContactSection from './components/ContactSection.vue';
import Navigation from './components/Navigation.vue';
import PaymentsSection from './components/PaymentsSection.vue';

export default {
  components: {
    ContactSection,
    Navigation,
    PaymentsSection,
  },
};
</script>

<style>
@font-face {
    font-family: 'Akrobat';
    src: url('@/assets/fonts/Akrobat-Black.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

body {
  font-family: 'Akrobat', Arial, sans-serif;
  background-color: #1e1e2e;
  color: white;
  margin: 0;
  padding: 0;
}

header {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 10px 20px;
  background-color: #1f1f2f;
  border-bottom: 1px solid #282846;
}

main {
  margin: 20px 10px;
  padding: 20px 10px;
  text-align: center;
}

.nav {
  margin-left: auto;
  width: auto;
  border-bottom: 3px solid #00ffb9;
  border-end-start-radius: 1.5px;
  border-end-end-radius: 1.5px;
  border-radius: 1.5px;
}

.logo {
  width: 60px; /* Уменьшение размера логотипа для мобильных устройств */
  border-radius: 10px;
  margin-right: 10px;
}

.name {
  text-align: left;
  margin: 0 10px;
  font-size: 2rem; /* Уменьшение размера текста для мобильных устройств */
  color: rgb(255, 255, 255);
}

footer {
  background-color: #2d2d44;
  text-align: center;
  padding: 20px;
  border-radius: 15px;
  margin: 20px 10px;
}

a {
  color: #00ffb9;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Медиазапросы для адаптивности */
@media (min-width: 768px) {
  .logo {
    width: 80px; /* Увеличение размера логотипа для планшетов и ПК */
  }

  .name {
    font-size: 3rem; /* Увеличение размера текста для планшетов */
  }

  footer {
    padding: 40px;
    margin: 20px;
  }
}

@media (min-width: 1024px) {
  .logo {
    width: 100px; /* Увеличение размера логотипа для ПК */
  }

  .name {
    font-size: 4rem; /* Увеличение размера текста для ПК */
  }
}

@import './assets/styles/custom-scrollbar.css';
</style>