<template>
  <div class="footer">
    <div class="contact-section">
      <!-- <p>Присоединяйтесь к нашему <a href="https://discord.gg/FjEmd5wBJW" target="_blank">Дискорд серверу</a></p> -->
    </div>

    <div class="our-contacts">
      <h4>Наши контакты</h4>
      <p>Telegram: <a href="https://t.me/Zanders_Sup" target="_blank">@Zanders_Sup</a></p>
      <!-- <p>Discord: <a href="https://discord.gg/FjEmd5wBJW" target="_blank">zanders_support</a></p> -->
      <p>Mail: <a href="mailto:zanders.help@gmail.com">zanders.help@gmail.com</a></p>
      <p>Номер телефона: +7 (900) 633 43-30</p>
      <p>ИП Христенко Константин Антонович</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2d2d44;
  padding: 20px;
  margin: 10px;
}

.contact-section p {
  font-size: 1.8rem;
  text-align: center;
}

.contact-section a {
  color: #00ffb9;
  text-decoration: none;
}

.contact-section a:hover {
  text-decoration: underline;
}

.our-contacts {
  text-align: center;
  margin: 20px 0 0 0; /* Увеличивает отступ сверху для разделения блоков */
}

.our-contacts h4 {
  margin-bottom: 10px;
}

.our-contacts p {
  margin: 5px 0;
  line-height: 1.5;
}

.copyrightPayments {
  text-align: center;
  margin-top: 20px;
}

/* Адаптация для более широких экранов */
@media (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .contact-section p {
    font-size: 2.5rem;
    text-align: left;
  }

  .our-contacts {
    text-align: right;
    margin: 0;
  }
}
</style>
