<template>
  <div class="main">
    <h1>Каталог товаров</h1>
  </div>

  <section v-for="(gameGroup, index) in products" :key="index" class="product-group">
    <div class="game-title">
      <h2>{{ gameGroup.game }}</h2>
    </div>
    <div class="products">
      <ProductCard
        v-for="product in gameGroup.products"
        :key="product.id"
        :title="product.title"
        :price="product.price + ' руб.'"
        :description="product.description"
        :img="require(`@/assets/img/${product.img}`)"
        :id="product.id"
      />
    </div>
  </section>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import { shopItems } from '@/assets/db/shopItems.js';

export default {
  name: "CatalogPage",
  components: {
    ProductCard
  },
  data() {
    return {
      products: shopItems
    };
  }
};
</script>

<style scoped>
h1 {
  align-items: center;
}

.product-group {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирует дочерние элементы по горизонтали */
  margin-bottom: 40px;
}

.game-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;  /* Ширина прямоугольника */
  height: 60px;  /* Высота прямоугольника */
  background-color: #00ffb9;
  color: #1f1f2f;
  border-radius: 5px;
  margin-bottom: 20px;
}

.game-title h2 {
  margin: 0;
  font-size: 4rem;
  color: #1f1f2f;  /* Цвет текста */
}

.products {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  flex-wrap: wrap;
  background: linear-gradient(180deg, #1f1f2f, #1e1e2e);
}

.products > * {
  flex-basis: 100%;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .products > * {
    flex-basis: 45%; /* Две колонки на планшетах */
    margin-bottom: 20px;
  }
}

@media (min-width: 1024px) {
  .products > * {
    flex-basis: 22%; /* Четыре колонки на ПК */
  }
}
</style>
