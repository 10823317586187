import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Catalog from '@/views/Catalog.vue';
import About from '@/views/About.vue';
import ProductPage from '../views/ProductPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: Catalog,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: ProductPage,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Если есть сохраненная позиция (при навигации назад), возвращаемся к ней
    if (savedPosition) {
      return savedPosition;
    } else {
      // Если нет сохраненной позиции, прокручиваем в начало страницы
      return { top: 0 };
    }
  }
});

export default router;